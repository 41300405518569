import {
  Box,
  // Button,
  // Heading,
  // Image,
} from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  faApple,
  faFacebookF,
  faInstagram,
  faSpotify,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { InputField } from "../../../components/StyledComponent/InputField";
import Swal from "sweetalert2";
import $ from "jquery";
import Button from "../../../components/Form/Button";
import SPEAKERS from "../../../assets/images/speakers.png";
import SPEAKERS_MOBILE from "../../../assets/images/speaker_mobile2.png";
import WMM from "../../../assets/images/wmm.png";
import WMM_MOBILE from "../../../assets/images/wmm_mobile.png";
import WMM_LOGO from "../../../assets/images/wmm_logo.png";
import LOGO_300 from "../../../assets/images/300_logo.png";
import TEMPLATE from "../../../assets/images/template.png";
import X_TWITTER from "../../../assets/images/X_Icon.png";
import ADDED_TEXT from "../../../assets/images/text.png";
import { toPng } from 'html-to-image';
import { domToPng } from 'modern-screenshot'
import { isMobile } from "../../../constants";

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const UploadButton = styled.a`
  position: absolute;
  left: 31.5%;
  top: ${iOS() ? "53.85%" : "51.32%"};
  width: 16.66%;
  height: 2.3%;
  z-index: 60;

  @media (max-width: 390px) {
    top: ${iOS() ? "53%" : "52.82%"};
  }

  @media (min-width: 768px) {
    left: 41.3%;
    top: 70.7%;
    width: 7%;
    height: 4.5%;
  }
`;

const SubmitButton = styled.a`
  position: absolute;
  left: 50.36%;
  top: ${iOS() ? "53.85%" : "51.32%"};
  width: 16.74%;
  height: 2.3%;
  z-index: 60;

  @media (max-width: 390px) {
    top: ${iOS() ? "53%" : "52.82%"};
  }

  @media (min-width: 768px) {
    left: 50.45%;
    top: 70.7%;
    width: 7%;
    height: 4.5%;
  }
`;

const SpeakerImg = styled.img`
  &.for-ios {
    height: 16rem;
  }

  @media (max-width: 390px) {
    &.for-ios {
      height: 14rem;
    }
  }
`;

const ImgContainer = styled.div`
  &.for-ios {
    margin-top: -7.7rem;
    margin-left: -0.1rem;
    height: 16%;
  }

  @media (max-width: 390px) {
    &.for-ios {
      margin-top: -3.825rem;
      margin-left: -0.15rem;
      height: 15%;
    }
  }
`;

const ScaleSliderContainer = styled.div`
  &.for-ios {
    margin-top: 2.5rem;
  }

  @media (max-width: 390px) {
    &.for-ios {
      margin-top: 0.625rem;
    }
  }
`;

const ButtonContainer = styled.div`
  &.for-ios {
    margin-top: 19rem;
  }

  @media (max-width: 390px) {
    &.for-ios {
      margin-top: 8rem;
    }
  }
`;

const Showcase = () => {
  const [imgFile, setImgFile] = useState();
  const [caption, setCaption] = useState();
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File>();
  const elementRef: any = useRef(null);
  const imgRef: any = useRef(null);
  const imgRef2: any = useRef(null);

  // function handleChange(e: any) {
  //   setImgFile(URL.createObjectURL(e.target.files[0]));
  // }

  const htmlToImageConvert = () => {
    const canvasElement = elementRef.current as HTMLDivElement;

    Swal.fire({
      title: "Rendering image...",
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    domToPng(canvasElement).then(dataUrl => {
      const link = document.createElement('a')
      link.download = fileName;
      link.href = dataUrl
      // link.click()

      var imgdata = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
      $.ajax({
        url: '/imageUploader.php',
        data: {
          imgdata: imgdata,
          fileName: fileName
        },
        type: 'post',
        success: function (response) {

        }
      });

      const imageURL = () => {
        Swal.fire({
          html: `
          <div class="flex flex-row justify-center items-center">
            <a href="https://www.facebook.com/sharer.php?u=${window.location.href}share/${fileName}&imageurl=${window.location.href}share/${fileName}" target="_blank"><i class="fab fa-facebook text-[#0000ff] text-2xl mx-2"></i></a>
            <a href="https://twitter.com/share?text=300 Women's Month&url=${window.location.href}share/${fileName}&300ent=300WomensMonth" target="_blank"><img src="${X_TWITTER}" class="w-6 mx-2" /></a>
            <a href="mailto:?subject=300 Womens' Month&body=#300WomensMonth%0D%0A${window.location.href}share/${fileName}%0D%0A%0D%0A"><i class="far fa-envelope text-gray-700 text-2xl mx-2"></i></a>
          </div>
          `,
          imageUrl: dataUrl,
          showCancelButton: true,
          cancelButtonText: 'Close',
          confirmButtonText: 'Download Image',
          preConfirm: () => {
            link.click();
            return false;
          },
          didOpen: () => {
            Swal.showValidationMessage("If the image above doesn't load, please re-submit.");
          },
        });
      }

      setTimeout(function() {
        imageURL();
      }, 3000);
    });

    // toPng(canvasElement, { cacheBust: false })
    //   .then((dataUrl) => {
    //     const link = document.createElement("a");
    //     link.download = fileName;
    //     link.href = dataUrl;

    //     toPng(canvasElement, { cacheBust: false })
    //       .then((dataUrl2) => {
    //         const link = document.createElement("a");
    //         link.download = fileName;
    //         link.href = dataUrl2;

    //         toPng(canvasElement, { cacheBust: false })
    //           .then((dataUrl3) => {
    //             const link = document.createElement("a");
    //             link.download = fileName;
    //             link.href = dataUrl3;
  
    //             toPng(canvasElement, { cacheBust: false })
    //               .then((dataUrl4) => {
    //                 const link = document.createElement("a");
    //                 link.download = fileName;
    //                 link.href = dataUrl4;

    //                 const imageBlob = fetch(dataUrl4)
    //                 .then((response) => response.blob())
    //                 .then((blob) => {
    //                   return URL.createObjectURL(blob);
    //                 });

    //                 var imgdata = dataUrl4.replace(/^data:image\/(png|jpg);base64,/, "");
    //                 $.ajax({
    //                   url: '/imageUploader.php',
    //                   data: {
    //                     imgdata: imgdata,
    //                     fileName: fileName
    //                   },
    //                   type: 'post',
    //                   success: function (response) {

    //                   }
    //                 });

    //                 const imageURL = () => {
    //                   Swal.fire({
    //                     html: `
    //                     <div class="flex flex-row justify-center items-center">
    //                       <a href="https://www.facebook.com/sharer.php?u=${window.location.href}share/${fileName}&imageurl=${window.location.href}share/${fileName}" target="_blank"><i class="fab fa-facebook text-[#0000ff] text-2xl mx-2"></i></a>
    //                       <a href="https://twitter.com/share?text=300 Women's Month&url=${window.location.href}share/${fileName}&300ent=300WomensMonth" target="_blank"><img src="${X_TWITTER}" class="w-6 mx-2" /></a>
    //                       <a href="mailto:?subject=300 Womens' Month&body=#300WomensMonth%0D%0A${window.location.href}share/${fileName}%0D%0A%0D%0A"><i class="far fa-envelope text-gray-700 text-2xl mx-2"></i></a>
    //                     </div>
    //                     `,
    //                     imageUrl: dataUrl4,
    //                     showCancelButton: true,
    //                     cancelButtonText: 'Close',
    //                     confirmButtonText: 'Download Image',
    //                     preConfirm: () => {
    //                       link.click();
    //                       return false;
    //                     },
    //                     didOpen: () => {
    //                       Swal.showValidationMessage("If the image above doesn't load, please re-submit.");
    //                     },
    //                   });
    //                 }

    //                 setTimeout(function() {
    //                   imageURL();
    //                 }, 3000);
    //               })
    //               .catch((err) => {
    //                 console.log(err);
    //               });
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

  const handleSliderChange = (e: any) => {
    const scaleImg = imgRef.current as HTMLImageElement;
    const scaleImg2 = imgRef2.current as HTMLImageElement;
    
    scaleImg.style.transform = "scale(" + e.target.valueAsNumber + ")";
    scaleImg2.style.transform = "scale(" + e.target.valueAsNumber + ")";
  }

  const handleUploadImage = async () => {
    const canvasElement = elementRef.current as HTMLDivElement;
    canvasElement.style.left = '0px';
    const { value: file } = await Swal.fire({
      title: "Select an image",
      input: "file",
      inputAttributes: {
        "accept": "image/*",
        "aria-label": "Upload your picture"
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      willOpen: () => {
        scrollTop();
      },
      willClose: () => {
        scrollTop();
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setFileName(file.name);
        setFile(file);
        setImgFile(e.target.result);
      };
      reader.readAsDataURL(file);

      $("#image_slider").css('display', 'flex');

      const { value: caption } = await Swal.fire({
        title: "Enter Your Name",
        input: "text",
        showCancelButton: true,
        inputAttributes: {
          "maxlength": "28"
        },
        willOpen: () => {
          scrollTop();
        },
        willClose: () => {
          scrollTop();
        },
      });
      if (caption) {
        setCaption(caption);
      }
    }
  }

  return (
    <Box
      className={`relative flex flex-col justify-center items-center h-screen w-full overflow-hidden ${iOS() ? "-mt-12" : "-mt-5"}`}
    >
      <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
        <img src={isMobile ? WMM_MOBILE : WMM} className={`md:w-8/12 -mb-5 -mt-56 md:-mt-10`} />
        <h2 className={`${iOS() ? "w-2/3" : "w-4/5"} md:w-1/2 hidden md:block text-center font-knockout uppercase text-xl xl:text-2xl 3xl:text-3xl mb-12 md:mb-3`}>CUSTOMIZE YOUR PHOTO AND CELEBRATE WOMEN IN MUSIC MONTH WITH US!</h2>
        <div className="w-full mt-2 mb-[3.25rem] md:hidden">
          <img src={ADDED_TEXT} className="w-3/4 m-auto" />
        </div>
        <SpeakerImg src={isMobile ? SPEAKERS_MOBILE : SPEAKERS} className={`object-cover md:object-contain w-full ${iOS() ? "for-ios" : "h-56"} md:h-auto md:w-3/4 mr-[0.15rem] md:mr-[1.15rem] 3xl:mr-[1.35rem]`}></SpeakerImg>
        <h2 className="font-knockout uppercase text-xl md:text-2xl 3xl:text-3xl block md:hidden -mt-3">presented by 300 entertainment</h2>
      </Box>
      <ImgContainer className={`z-20 absolute bg-black w-[50%] md:w-[27%] ${iOS() ? "for-ios" : "-mt-32 h-[17%]"} md:h-[28%] lg:h-[33%] xl:h-[36%] md:mt-5 3xl:mt-7 mr-1 md:mr-0 overflow-hidden`}>
        <img ref={imgRef} src={imgFile} className="w-full h-full object-contain" />
      </ImgContainer>
      <ScaleSliderContainer id="image_slider" className={`absolute z-40 hidden w-3/4 md:w-1/4 pl-20 md:pl-16 pr-20 ${iOS() ? "for-ios" : "mt-5"} md:mt-56 3xl:mt-72`}>
        <input type="range" min="1" max="4" className="w-full" defaultValue="1" step="0.01" onChange={handleSliderChange} />
      </ScaleSliderContainer>
      <ButtonContainer className={`z-50 absolute flex md:hidden ${iOS() ? "for-ios" : "mt-64"}`}>
        <button className="border border-[#dc8cf6] bg-white text-[#dc8cf6] text-2xl font-knockout font-thin uppercase px-10 mr-3" onClick={handleUploadImage}>upload</button>
        <button className="border border-[#dc8cf6] bg-white text-[#dc8cf6] text-2xl font-knockout uppercase px-10 ml-3" onClick={htmlToImageConvert}>submit</button>
      </ButtonContainer>
      <Box className="absolute z-50 hidden md:flex md:bottom-0 3xl:bottom-2 uppercase font-knockout justify-between items-end w-full">
        <img src={WMM_LOGO} className="h-auto object-contain md:w-32 3xl:w-40 md:h-32 3xl:h-40 ml-5" />
        <h2 className="md:text-2xl 3xl:text-3xl">presented by 300 entertainment</h2>
        <img src={LOGO_300} className="h-auto object-contain object-bottom md:w-16 3xl:w-16 md:h-16 3xl:h-16 mr-5 ml-20" />
      </Box>
      <Box className={`absolute z-50 flex flex-col md:hidden justify-center items-center ${iOS() ? "bottom-[4.5rem]" : "bottom-12"} uppercase font-knockout justify-between items-end w-full`}>
        <img src={WMM_LOGO} className={`h-auto object-contain w-20 ${iOS() ? "mb-7" : "mb-8"}`} />
        <img src={LOGO_300} className="h-auto object-contain object-bottom w-16" />
      </Box>
      <UploadButton
        className="bg-black/0 cursor-pointer hidden md:block"
        onClick={handleUploadImage}
      ></UploadButton>
      <SubmitButton className="bg-black/0 cursor-pointer hidden md:block" onClick={htmlToImageConvert}></SubmitButton>
      <div ref={elementRef} className="absolute -z-[100] -left-[10000px] top-0 flex w-[800px] h-[800px]">
        <img src={TEMPLATE} className="absolute w-full h-full object-contain" />
        <Box className="flex flex-col w-full h-full justify-center items-center">
          <img src={WMM} className={`absolute top-0 w-2/3 pt-16`} />
          <Box className="-z-10 w-[46%] h-[29%] bg-black mt-6">
            <img ref={imgRef2} src={imgFile} className="object-contain h-full w-full" />
          </Box>
          <Box className="z-10 text-black">
            <Box className="mt-3 font-annabelle text-3xl" dangerouslySetInnerHTML={{ __html: (!caption ? "&nbsp;" : caption) }}></Box>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default Showcase;
