import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";
import BG_IMG from "../../assets/images/bg_desktop2.png";
import BG_IMG_MOBILE from "../../assets/images/bg_mobile.png";
import { isMobile } from "../../constants";

export default function Home() {
  return (
    <Box className="text-white h-screen w-full flex flex-col justify-start md:justify-center items-center">
      <img src={isMobile ? BG_IMG_MOBILE : BG_IMG} className="absolute w-full h-screen object-fill" />
      <Showcase />
    </Box>
  );
}
